import PrimitiveEntityType from '../../common/enums/primitiveEntityType';
import DayOfWeekSelect from '../components/dayOfWeekSelect.js';
import FieldSelect from '../../common/components/fieldSelect.js';
import FieldInput from '../../common/components/fieldInput.js';
import CheckboxInput from '../../common/components/checkboxInput.js';
import FieldDynamic from '../../common/components/fieldDynamic.js';
import FieldTemporalAmountInput from './fieldTemporalAmountInput.js';
import FieldTemporalAccesorInput from './fieldTemporalAccesorInput.js';
import MultilingualString from '../../common/models/multilingualString.js';
import typeFilterKinds from '../../common/enums/typeFilterKinds.js';
import ModelFactory from '../../common/models/modelFactory';
import Constants from '../../common/models/constants';
import BaseSelect from '../../common/components/baseSelect';
import TaskKind from '../../common/enums/taskKind';
import TaskResult from '../../common/enums/taskResult';
import TaskState from '../../common/enums/taskState';
import enumsMap from '../../common/enums/enumsMap'
import FilterModalValueViewStub from '../components/filterModalValueViewStub.js'


var FilterModal = Backbone.View.extend({

	initialize: function (options) {
		this.types =options.types
		this.fields = options.fields;
		this.appFields = options.appFields
		const Type = ModelFactory.getModelType(this.types.get(Constants.ID_TYPE_FIELD_FILTER));
		this.filter = new Type();
		this.tableFilter = options.tableFilter;
		this.setDefaultValue = true;
		this.dataSource = options.dataSource;
	},

	render: function () {
		this.button = this.$el.find('.add-filter');
		this.widget = this.$el.find('.filter-dropdown');
		this.dropdown = this.$el.find('.filter-dropdown-menu');
		this.select = this.$el.find('.filter-dropdown-menu');
		this.selectKindBtn = this.$el.find('.select-kind');
		this._initializeWidgetEvents();

		let fields = this.fields() || [];
		if (fields.length !== 0) {
			this.buildFilterFieldSelect(this.fields());
		} else {
			this.tableFilter.disable();
		}
	},

	_initializeWidgetEvents: function () {
		var that = this;

		this.widget.on('show.bs.dropdown', function (e) {
			that._onShowDropDown(e);
		});

		this.widget.on('shown.bs.dropdown', function (e) {
			that._onShownDropDown(e);
		});

		this.widget.on('hide.bs.dropdown', function (e) {
			that._onHideDropDown(e);
		});

		this.dropdown.click((e) => {
			this.hideInnerDropdowns(e);
			e.stopPropagation();
		});

		this.dropdown.find('.fields').change((e) => this.fieldChanged(e));
		this.dropdown.find('.kinds').on('click', 'li', (e) => this.kindsChanged(e));
		this.dropdown.find('.apply-filter').click((e) => this.applyFilterClicked(e));
		this.dropdown.find('.cancel-filter').click((e) => this._hideDropdown());
		this.selectKindBtn.click((e) => {
			if (this.selectKindBtn.parent().hasClass('open')) {
				this.selectKindBtn.parent().addClass('closing');
			} else {
				this.selectKindBtn.parent().addClass('opening');
			}
		});

		this.dropdown.on('select2:opening', 'select', () => {
			this.selectOpened = true;
		});

		this.dropdown.on('select2:close', 'select', () => {
			this.selectOpened = false;
		});

		this.selectKindBtn.parent().on('shown.bs.dropdown', (e) => {
			this.selectKindBtn.parent().removeClass('opening');
		});

		this.selectKindBtn.parent().on('hidden.bs.dropdown', (e) => {
			this.selectKindBtn.parent().removeClass('closing');
		});
	},

	hideInnerDropdowns: function (e) {
		if (!e || !$.contains(this.dropdown.find('.value-block')[0], e.target)) {
			this.valueView && this.valueView._hideDropdown && this.valueView._hideDropdown();
		}
		if (this.selectKindBtn.parent().hasClass('open')) {
			this.selectKindBtn.parent().addClass('closing');
			this.selectKindBtn.dropdown('toggle');
		}
	},

	_onShowDropDown: function (e) {
		$('body').append(this.dropdown);
		this.selectKindBtn.dropdown();
	},

	_onShownDropDown: function (e) {
		const dropdownWidth = this.dropdown.width();
		const dropdownHeight = this.dropdown.height();
		const widgetWidth = this.widget.width();
		const widgetHeight = this.widget.height();

		let dropdownTop = (this.positionWidget || this.widget).offset().top + (this.positionWidget || this.widget).height();
		let positionLeft = (this.positionWidget || this.widget).offset().left;
		if (this.positionWidget) {
			positionLeft += 5;
		}
		let dropdownStartHorizontal = document.dir == "rtl" ? $(window).width() - positionLeft - this.widget.width() : positionLeft;

		const canAlignDefaultVertical = $(window).height() - dropdownTop - dropdownHeight > 0;
		dropdownTop = canAlignDefaultVertical ? dropdownTop : dropdownTop - dropdownHeight - widgetHeight;
		this.dropdown.css('top',  dropdownTop < 0 ? 0 : dropdownTop);
		
		const canAlignDefaultHorizontal = ($(window).width() - dropdownStartHorizontal - dropdownWidth) > 0;
		dropdownStartHorizontal = canAlignDefaultHorizontal ? dropdownStartHorizontal : dropdownStartHorizontal - dropdownWidth + widgetWidth;
		this.dropdown.css(document.dir == "rtl" ? 'right': 'left', dropdownStartHorizontal < 0 ? 0 : dropdownStartHorizontal);
		this.dropdown.css('display', 'block');

		if (this.setDefaultValue) {
			const Type = ModelFactory.getModelType(this.types.get(Constants.ID_TYPE_FIELD_FILTER));
			this.filter = new Type();
		}
		this.dropdown.find('.fields').trigger('change');
	},

	_onHideDropDown: function (e) {
		if (this.dropdown.find('.opening, .closing').length != 0 || this.selectOpened) {
			e.preventDefault();
			return;
		}
		this.hideInnerDropdowns();
		this.positionWidget = null;
		this.dropdown.hide();
		this.widget.append(this.dropdown);
	},

	change: function (filter, positionWidget) {
		this._hideDropdown();

		this.filter = filter;
		this.positionWidget = positionWidget;

		this.setDefaultValue = false;
		this.dropdown.find('.fields').val(this.filter.get('field').id);
		this._showDropdown();
	},

	_showDropdown: function () {
		if (this.widget && !this.widget.hasClass('open')) {
			this.button.dropdown('toggle');
		}
	},

	_hideDropdown: function () {
		if (this.widget && this.widget.hasClass('open')) {
			this.button.dropdown('toggle');
		}
	},

	_toggleDropdown: function () {
		this.button && this.button.dropdown('toggle');
	},

	getPrimitiveType() {
		var type = this.filter.get('field').get('fieldTypeId') &&
			this.types.get(this.filter.get('field').get('fieldTypeId')).toJSON();
		return (type && type.primitiveEntityType) ? type.primitiveEntityType : null;
	},

	applyFilterClicked: function () {
		if (!this.filter.get('kind') || !this.filter.get('field')) {
			return null;
		}
		if(this.getPrimitiveType() == PrimitiveEntityType.ENUM) {
			this.filter.set('value' , this.getBaseEnum().getAll().indexOf(this.filter.get('value')))
		}
		this.filter.set('isVisible', true)
		const notAdded = this.tableFilter.addFilter(this.filter, true)
		if (this.tableFilter.isOrPresent() && !notAdded) {
			const Type = ModelFactory.getModelType(this.types.get(Constants.ID_TYPE_FIELD_FILTER))
			let andFilter = new Type()
			andFilter.set('op', 'AND')
			this.tableFilter.addFilter(andFilter)
		} else {
			this.tableFilter.$el.trigger('filter-changed')
		}
		this._hideDropdown();
	},

	fieldChanged: function () {
		var that = this;
		var fieldId = this.dropdown.find('.fields').val();

		fieldId && that.filter.set('field', this.appFields.get(fieldId));
		this.filter.set('value', null)
		this.rebuildFilterValueElement();
		if (this.setDefaultValue) {
			let value = null;
			if (fieldId) {
				const field = this.appFields.get(fieldId);
				const fieldType = field.type();
				if (fieldType && fieldType.isPrimitive()) {
					if (fieldType.primitive() != PrimitiveEntityType.ENUM) {
						value = fieldType.primitiveDefaultValue();
					}
					if (fieldType.primitive() == PrimitiveEntityType.STRING || fieldType.primitive() == PrimitiveEntityType.SYSTEM_STRING) {
						value = '';
					}
				}
			}
			this.filter.set('value', value);
		}

		this.rebuildFilterKindSelect();
		this.setDefaultValue = true;
	},

	kindsChanged: function (e) {
		this.hideInnerDropdowns();
		this.dropdown.find('.select-kind').text(this.tableFilter.kindMapToChar[$(e.currentTarget).attr('data-value')]);
		this.filter.set('kind', $(e.currentTarget).attr('data-value'));
		this.rebuildFilterValueElement();
	},

	rebuildFilterKindSelect: function () {
		var primitiveEntityType =	this.getPrimitiveType();
		var $ul = this.dropdown.find('.kinds');

		$ul.empty();
		_.each(typeFilterKinds[primitiveEntityType || ''], (value) => {
			const $li = $('<li>');
			$li.attr('data-value', value);
			$li.append($('<a style="cursor: pointer;">')
				.html('<span style="margin-right: 10px;">' + this.tableFilter.kindMapToChar[value] +
				'</span> ' + this.tableFilter.kindMapToText()[value]));
			$ul.append($li);
		});
		if (this.setDefaultValue) {
			this.kindsChanged({currentTarget: $ul.find('li').first()[0]});
		} else {
			this.kindsChanged({currentTarget: $ul.find('li[data-value="' + this.filter.get('kind') + '"]').first()[0]});
		}
	},

	rebuildFilterValueElement: function () {
		if (this.valueView) {
			this.valueView.undelegateEvents();
			this.valueView.$el && this.valueView.$el.removeData().unbind();
			this.valueView.remove();
			this.valueView.model && this.valueView.model.unbind()
		}
		this.dropdown.find('.value-block').empty();
		this.dropdown.find('.value-block').append(this.getFilterValueElement());
		this.initializeValueElement();
	},

	initializeValueElement: function () {
		var that = this;
		var filterField = this.filter.get('field')
		if (this.filter.get('kind') === 'NULL' || this.filter.get('kind') === 'NOT_NULL') {
			return;
		}
		this.dropdown.find('.value-block .enumType').each(function (i, select) {
			let baseEnum = that.getBaseEnum()
			that.filter.set('baseEnum' , baseEnum)
			that.valueView = new BaseSelect({
				el: $(select),
				model: that.filter,
				modelAttr: 'value',
				data: baseEnum.getAll()
			})
			that.valueView.setValue(baseEnum.getAll()[0]);
		});
		this.dropdown.find('.value-block .complexType').each(function (i, select) {
			var $select = $(select);
			that.valueView = new FieldSelect({
				el: $select,
				model:that.filter,
				modelAttr:'value',
				dataSource: that.dataSource && that.dataSource(that.filter.get('field'))
			});
			that.valueView.setValue(that.getFilterValue() || null);
		});

		this.dropdown.find('.value-block .dynamicType').each(function (i, select) {
			var $select = $(select);
			that.valueView = new FieldDynamic({
				el: $select
			});
			$select.change(function () {
				if (that.valueView.getValue()) {
					that.filter.set('value', that.valueView.getValue());
				} else {
					that.filter.set('value', null);
				}
			});
			that.valueView.setValue(that.getFilterValue() || null);
		});

		this.dropdown.find('.value-block input, .value-block .day-of-week, .value-block .temporal-amount').each(function (i, input) {
			var $input = $(input);
			var fieldType = that.filter.get('field').get('fieldTypeId') &&
				that.types.get(that.filter.get('field').get('fieldTypeId')).toJSON();
			if (fieldType.primitiveEntityType === PrimitiveEntityType.MONTH ||
			fieldType.primitiveEntityType === PrimitiveEntityType.TIMESTAMP ||
			fieldType.primitiveEntityType === PrimitiveEntityType.LOCAL_DATE ||
			fieldType.primitiveEntityType === PrimitiveEntityType.LOCAL_TIME ||
			fieldType.primitiveEntityType === PrimitiveEntityType.LOCAL_DATE_TIME ||
			fieldType.primitiveEntityType === PrimitiveEntityType.MONTH_DAY ||
			fieldType.primitiveEntityType === PrimitiveEntityType.YEAR ||
			fieldType.primitiveEntityType === PrimitiveEntityType.YEAR_MONTH) {
				that.valueView = new FieldTemporalAccesorInput({
					el: $input,
					model: that.filter,
					modelAttr: 'value'
				});
			} else if (fieldType.primitiveEntityType === PrimitiveEntityType.DURATION) {
				that.valueView = new FieldTemporalAmountInput({
					el: $input,
					model: that.filter,
					modelAttr: 'value'
				});
			} else if (fieldType.primitiveEntityType === PrimitiveEntityType.DAY_OF_WEEK) {
				that.valueView = new DayOfWeekSelect({
					el: $input,
					model: that.filter,
					modelAttr: 'value'
				});
			} else if (fieldType.primitiveEntityType === PrimitiveEntityType.BOOLEAN || fieldType.primitiveEntityType === PrimitiveEntityType.DOCUMENT_REGISTER_STATE) {
				new CheckboxInput({
					el: $input[0],
					model: that.filter,
					modelAttr: 'value'
				})
				that.valueView = new FilterModalValueViewStub()
			} else {
				that.valueView = new FieldInput({
					el: $input,
					model: that.filter,
					modelAttr: 'value'
				});
			}
			that.valueView.render();
		});
	},

	buildFilterFieldSelect: function (fields) {
		var data = [];
		var $select = this.dropdown.find('.fields');
		_.each(fields, (value) => {
			data.push({
				id: value.id,
				text: MultilingualString.getCurrentValue(value.name)
			});
		});
		new BaseSelect({el:$select, data:data});
		$select.val(
				this.filter.get('field') && this.filter.get('field').id ||
				fields[0] && fields[0].id)
			.trigger('change');
	},

	getFilterValueElement: function () {
		var type = this.filter.get('field').get('fieldTypeId') &&
			this.types.get(this.filter.get('field').get('fieldTypeId')).toJSON();
		var primitiveEntityType = (type && type.primitiveEntityType) ? type.primitiveEntityType : null;
		var fieldKind = this.filter.get('field').get('fieldKind');
		var $element = $('<input>').attr('type', 'text').addClass('form-control');
		if (this.filter.get('kind') === 'NULL' || this.filter.get('kind') === 'NOT_NULL') {
			$element.attr('disabled', 'disabled');
		} else {
			switch (primitiveEntityType) {
				case PrimitiveEntityType.BOOLEAN:
					$element = $('<input style="margin-left: auto; margin-right: auto;">')
						.attr('type', 'checkbox').addClass('form-control');
					break;
				case PrimitiveEntityType.DURATION:
					$element = $('<div class="temporal-amount"></div>');
					break;
				case PrimitiveEntityType.DAY_OF_WEEK:
					$element = $('<select class="day-of-week">');
					break;
				case PrimitiveEntityType.DECIMAL:
					$element.attr('data-scale', type.primitiveTypeProperties.decimalScale);
					break;
				case PrimitiveEntityType.ENUM:
					$element = $('<select>').addClass('enumType')
				default:
					break;
			}
			if (fieldKind == 'REGULAR' && !primitiveEntityType) {
				$element = $('<select>').addClass('complexType');
			} else if (fieldKind == 'DYNAMIC') {
				$element = $('<select>').addClass('dynamicType');
			}
			$element
				.addClass('value-element')
				.attr('data-entity-type-id', this.filter.get('field').get('fieldTypeId'))
				.attr('data-field', 'field' + this.filter.get('field').id)
				.attr('data-filter-id', this.filter.cid)
				.attr('data-create-new-action', 'dont.show.create.new')
				.attr('data-primitive-type', primitiveEntityType)
				.attr('data-width', 200);
		}
		return $element.prop('outerHTML');
	},

	setPrimitiveFilterValue: function (value, fieldType) {
		switch (fieldType.primitiveEntityType) {
			case PrimitiveEntityType.DECIMAL:
				this.filter.set('value', value / Math.pow(10,
					fieldType.primitiveTypeProperties.decimalScale));
				break;
			default:
				this.filter.set('value', value);
				break;
		}
	},

	getFilterValue: function () {
		var fieldType = this.filter.get('field').get('fieldTypeId') &&
			this.types.get(this.filter.get('field').get('fieldTypeId')).toJSON();
		if (!fieldType) {
			return this.filter.get('value');
		} else if (!fieldType.primitiveEntityType) {
			return {id: this.filter.get('value') && this.filter.get('value').id,
				objectType: {id: this.filter.get('field').get('fieldTypeId')}};
		} else {
			this.filter.get('value');
			switch (fieldType.primitiveEntityType) {
				case PrimitiveEntityType.DECIMAL:
					return this.filter.get('value') * Math.pow(10,
						fieldType.primitiveTypeProperties.decimalScale);
					break;
				case PrimitiveEntityType.ENUM:
					let value = this.filter.get('value')
					return getBaseEnum().getAll().find().indexOf(value)
					break;
				default:
					return this.filter.get('value');
			}
		}
	},

	getBaseEnum() {
		var fieldType = this.types.get(this.filter.get('field').get('fieldTypeId')).toJSON();
		return enumsMap[fieldType.primitiveTypeProperties.enumName]
	}

});

export default FilterModal;
