import entityPresenter from '../../entity/views/entityPresenter';
import ErrorCode from '../../common/enums/errorCode';
import { initFiltersEntityTable, getComparator } from '../../utils/tableUtils'
import { translate } from '../../common/service/stringResourceService'
import { multilingualStringService } from '../../common/service/multilingualStringService'
import utils, {showInstance, scaleDecimals} from '../../common/components/utils.js'
import WidgetSorting from './widgetSorting.js'
import MultilingualString from '../../common/models/multilingualString.js';
import FieldSelect from '../../common/components/fieldSelect.js';
import States from '../../common/enums/states';
import CheckboxInput from "../../common/components/checkboxInput";
import { showConfirmModal } from '../../common/components/confirmModalVue'
import Entity from '../../common/models/entity.js';
import Entities from '../../common/collections/entities.js';
import TableState from '../../common/components/tableState'
import ModelFactory from '../../common/models/modelFactory';
import {getMoreDataForTable} from '../../entity/components/indexTable.js'

class Widget {

  constructor (options) {
    this.field = options.field
    this.renderedCards = new Map()
    this.model = options.model
    this.modelAttr = options.modelAttr
    this.el = options.el
    this.$el = $(options.el)
    this.context = options.context;
    this.single = this.el.getAttribute('data-single') || options.single || false
    this.viewId = this.el.getAttribute('data-view-id')
    this.canDelete = this.el.getAttribute('data-can-delete') === 'true'
    this.height = this.el.getAttribute('data-height')
    this.openMode = this.el.getAttribute('data-open-mode')
    this.linkFormView = this.el.getAttribute('data-link-form-view')
    this.editing = false
    this.t = Promise.resolve()
    this.disabledInEditor = this.el.getAttribute('data-disabled') == 'true';
    document.body.addEventListener('click', (e) => {
      if (this.editing) {
        let editingWillClose = true
        let el = e.target
        while (el !== document.body) {
          if (el == this.el) {
            editingWillClose = false
            break
          }
          el = el.parentElement
        }
        if (editingWillClose) {
          this.editing.wizardView.exitEditMode()
          this.editing = false
        }
      }
    })
    this.modalOpt = {
      modalWidth: this.el.getAttribute('data-modal-width'),
      modalHeight: this.el.getAttribute('data-modal-height'),
      modalFloat: this.el.getAttribute('data-modal-float')
    }
    let model = this.model.get(this.modelAttr);
    if (this.single) {
      this.columnsQuantity = 1
      if (app.userObservers && this.context && this.openMode == 'use.on.click.event') {
        let key = this.modelAttr
        if (app.userObservers.getOnOpenClicked(this.context.type.id + ',' + key)) {
          this._onClickCard = () => {
            app.userObservers.getOnOpenClicked(this.context.type.id + ',' + key).call(this.context, this.context.model, this.model)
          }
        }
      }
    } else {
      this.width = Number.parseInt(this.el.getAttribute('data-width') || '0', 10)
      this.pageSize = Number.parseInt(this.el.getAttribute('data-page-size'), 10)
      this.columnsQuantity = Number.parseInt(this.el.getAttribute('data-columns-quantity'), 10)
      this.hiddenStubs = this.width ? this.el.offsetWidth / (this.width + 22) : this.columnsQuantity
      this.widgetModels = this.model.get(this.modelAttr).models.filter(item => item.get('clientState') !== States.DELETED)
      this.model.get(this.modelAttr)._isForCardView = true
      this.filteredWidgetModels = []
      this.state = new TableState(this)
      this.selectedItems = []
      let func = app.userObservers.getRowClickFunction(app.fields.get(this.field.id).fieldName());
      if (func) {
        this._onClickCard = (card, e) => {
          func.call(options.context, model, model.get(card.id));
        }
      }
    }
    this.itemWidth = this.width || 'calc(' + 100 / this.columnsQuantity + '%' + ' - 22px)'
    let request
    if (app.builderMode) {
      request = () => {return utils.request(null, app.urls.create(this.field.type().id, this.viewId, {
        notransaction: true
      }), 'GET')}
    } else {
      request = () => {return utils.request(null, app.urls.presentationInfoForCard(this.field.type().id, this.viewId), 'GET')}
    }
    this.infoPromise = request().then((info) => {
      info.updating = false
      info.preventPageLeave = false
      info.canUpdate = false
      if (this.single) {
        const model = this.model.get(this.modelAttr)
        if (model) {
          info.objectId = model.id
        }
      }
      this.info = info
      if (!this.single){
        this.tableFilter = this.initFilters()
        this.tableSorting = this.initSorting()
        this.tableSorting.render()
        this.state.apply()
      }
      this.filesPromise = entityPresenter._loadFiles(info, app.builderMode)
      return new Promise((resolve, reject) => {
          return utils.ajaxRequest(null, info.htmlUrl, 'get', resolve, reject, false, null, !app.builderMode)
      }).then((html) => {
        this.html = html
      })
    })
    if (!app.builderMode) {
      // To trigger defining this.filteredWidgetModels for first rendering
      if (this.single) {
        if (this.model) {
          this.model.on('change:' + this.modelAttr, (m) => {
            const model = m.get(this.modelAttr)
            if (model) {
              this.info.objectId = model.id
            }
            this.render()
          })
        }
      } else {
        if (this.field.type().isEmbedded()) {
          let that = this
          this._get('add').on('click', (a) => {
            const ModelType = ModelFactory.getModelType(this.field.type());
            const rowModel = ModelType.fromJSON({});
            rowModel.set('clientState', States.NEW);
            let min = Number.POSITIVE_INFINITY
            this.model.get(this.modelAttr).forEach(row => min = Math.min(min, row.get('relativeOrder')))
            if (min == Number.POSITIVE_INFINITY){
              min = 1
            }
            rowModel.set('relativeOrder', min - 1);
            that.model.get(that.modelAttr).add(rowModel)
            const id = rowModel.getIdOrCid()
            setTimeout(() => {
              this.openCardForEditing(this.renderedCards.get(id))
            }, 0)
          })
        } else if (this.field.isVirtual()) {
          this._get('add').on('click', (a) => {
            showInstance({openMode: this.openMode,
      			   typeId: this.field.type().id,
      			   viewId: this.linkFormView,
      			   callback: (() => {
      				    let created = false
      				    return (data) => {
                    if (created) {
                      this.renderedCards.get(data.item.id).wizardView.getDataAfterSave()
                    } else {
                      this.addItem(data.item.id)
                      created = true
                    }
      				    }
      			   })(),
      			   previousContext: this.context,
      			   modalOpt: this.modalOpt
      		  })
          })
        } else {
          this.initAddItemSelect()
        }

        this.model && this.model.get(this.modelAttr).on('update reset', () => {
          this.reload()
        })
        this.$el.on('click', (e) => {
          if ($(e.target).hasClass('load-more-widget')) {
            this.renderPage(this.start, this.end)
          }
        })
        this._get('remove').on('click', (e) => {
          if (!e.currentTarget.classList.contains('disabled')) {
            showConfirmModal({
              title: translate('delete'),
              text: translate('action.delete.items.question'),
              confirmButtonText: translate('yes'),
              cancelButtonText: translate('no'),
              onConfirm: () => {
                this.removeSelected()
              }
            })
          }
        })
      }

    }
  }
  getStorageId () {
    return this.field.id+'f';
  }
  setExtendedMode () {

  }
  setFieldsToShow () {
    
  }
  async renderOne (options) {
    let container = $('<div class="widget-item"></div>')
    container.css('height', this.height + 'px')
    container.css('width', this.itemWidth)
    if (options.prepend){
      this.$el.prepend(container)
    } else {
      this.$el.append(container)
    }

    container.on('click', (e) => {
      this.onClickCard(options, e)
    })

    const rendered = !this.single && this.renderedCards.get(options.objectId)
    if (rendered && rendered.detached) {
      this.renderCheckbox(options, container, rendered.data.id);
      rendered.detached.appendTo(container)
    } else {
    let url =  app.urls.update(this.field.type().id, options.objectId, {
      formViewId: this.viewId,
      notransaction: true
    })
    if (!this.single){
      if (!options.objectId) {
        url = app.urls.create(this.field.type().id, this.viewId, {
          notransaction: true
        })
      }
    }
    if (!options.customContent){
      let containerWithForm = $(`<div/>`)
      container.append(containerWithForm)

      try {
        let widgetModel = {
          item: null,
          modifiedItem: null
        }
        let findWidgetModel = options.model
        if (!this.single){
          if (!findWidgetModel) {
            findWidgetModel = this.widgetModels.find((element, index, array) => {return element.getIdOrCid() == options.objectId})
          }
          if (!findWidgetModel) {
            findWidgetModel = new Entity({},{entityTypeId: this.field.type().id})
          }
          widgetModel.item = findWidgetModel.toServerJSON()
          this.renderCheckbox(options, container, findWidgetModel.getIdOrCid());
        }
        if (!this.html) {
          this.infoPromise.then(() => {
            entityPresenter._presentForm(this.info,
            {
              url: url,
              typeId: this.field.type().id,
              viewId: this.viewId,
              objectId: options.objectId,
              afterSaved: (data) => {},
              el: containerWithForm,
              ignoreBlocks: app.builderMode,
              filesPromise: this.filesPromise,
              viewControl: this,
              isWidget: !this.single,
              widgetModel: widgetModel,
              html: this.html,
              model: findWidgetModel
            }).then((a) => { this.renderedCards.set(options.objectId, a) })
          })
        } else {
           entityPresenter._presentForm(this.info,
          {
            url: url,
            typeId: this.field.type().id,
            viewId: this.viewId,
            objectId: options.objectId,
            afterSaved: (data) => {},
            el: containerWithForm,
            ignoreBlocks: app.builderMode,
            filesPromise: this.filesPromise,
            viewControl: this,
            isWidget: !this.single,
            widgetModel: widgetModel,
            html: this.html,
            model: findWidgetModel
          }).then((a) => { this.renderedCards.set(options.objectId, a) })
        }


      } catch (e) {
        if (e.message == ErrorCode.META_DATA_IS_STALE) {
          app.notificationManager.addError(
            MultilingualString.fromStringInCurrentLanguage(translate('stale.meta.data'))
          );
        } else {
          throw e;
        }
      }
    } else if (options.hiddenStub) {
      container.addClass('hidden-stub custom')
      container.css('height', 0)
    } else {
      container.append(options.customContent)
      container.addClass('custom')
    }
  }
  }
  renderCheckbox(options, container, widgetId) {
    if (!options.customContent && !app.builderMode){
      if (this.canDelete) {
        let containerWithSelectionCheckbox = $(`<div class="selectionCheckbox"> <input type="checkbox" data-primitive-type="BOOLEAN"/> </div>`)
        container.append(containerWithSelectionCheckbox)

        let selectionCheckbox = new CheckboxInput({
            el: containerWithSelectionCheckbox.find('input')[0],
            model: new Backbone.Model({id: widgetId}),
            modelAttr: 'isSelected'
        })
        selectionCheckbox.model.on('change:isSelected', (model, value) => this.selectionChanged(model, value))
        selectionCheckbox.render()
      }
    }
  }
  removeCustom () {
    this.$el.children('.widget-item.custom').remove()
  }

  _renderPage(start, end, renderLoadMore) {
    for (let i = start; i < end; i++) {
      let model = this.filteredWidgetModels[i]
      if (!model) break
      this.renderOne({
        objectId: model.getIdOrCid(),
        model:model
      })
    }
    if (renderLoadMore) {
      this.renderLoadMore()
    }
    this.start = end
    this.end = end + this.pageSize
    this.addHiddenStubsForRow()
  }

  renderPage (start, end) {
    this.removeCustom()
    if (this.field.isVirtual()){
      const clear = start == 0 ? true : false
      let data = this.filteredWidgetModels && this.filteredWidgetModels.length > 0 && [{id: this.filteredWidgetModels[this.filteredWidgetModels.length - 1].id}]
      return getMoreDataForTable({
        size: this.pageSize,
        order: this.model.getViewContext().getOptionsForField(this.field.id).getOrder(),
        clear: clear,
        loadItems: true,
        loadFilteredSize: true,
        loadTotalSize: false
      }, {
        typeId: this.field.type().id ,
        viewId: this.viewId,
        filters: this.tableFilter,
        context: {
          type: this.field.type()
        },
        setFilteredSize: () => {},
        setTotalSize: () => {},
        data: data
      }).then((data) => {
        let models = new Entities(data.items, {
          entityTypeId: this.field.type().id
        })
        if (clear) {
          this.widgetModels = new Entities([], {
            entityTypeId: this.field.type().id
          })
        }
        this.widgetModels.add(models.models)
        this.filteredWidgetModels = this.widgetModels.models
        this._renderPage(start, end, data.totalSize > end)
      })
    } else {
      this._renderPage(start, end, this.filteredWidgetModels.length > end)
    }
  }
  addHiddenStubsForRow () {
    for (let i =  0; i < this.hiddenStubs; i++) {
      this.renderOne({
        hiddenStub: true,
        customContent: true
      })
    }
  }
  renderLoadMore () {
    this.renderOne({
      customContent: `<div style="height:100%;width:100%" class="load-more-widget">${multilingualStringService.formatSystemString(translate('load.more'),[''])}</div>`
    })
  }
  empty() {
    this.widgetModels && this.widgetModels.forEach((m) => {
      let card = this.renderedCards.get(m.getIdOrCid())
      if (card) {
        card.detached = card.$el.detach()
      }
    })
    this.$el.empty()
  }
  _render () {
	  return this.renderPage(0, this.pageSize)
  }
  render () {
    if (this.single) {
      this.$el.empty()
      const model = this.model.get(this.modelAttr)
      if (model) {
        this.renderOne({
          objectId: model.getIdOrCid(),
          model: model
        })
      }
    } else {
      this.reload()
    }
  }
  _get (shortId) {
    return this.$el.parent().find('.card-' + this.field.id + '_' + shortId).first()
  }

  focus () {
  //  this.checkbox.focus()
  }
  openCardForEditing(context) {
    if (!this.disabled){
      if (this.editing){
        this.editing.wizardView.exitEditMode()
        this.editing = false
      }
      context.wizardView.edit()
      this.editing = context
    }
  }
  onClickCard (options, e) {
    if (options.customContent) {
      return
    }
    let cardContext = this.renderedCards.get(options.objectId)
		let target=$(e.target)

		if (cardContext && !cardContext.updating && !((target.parents('button')[0]) ||
      (target.parents('a')[0]) ||
      e.target.nodeName.toLowerCase()=='a' ||
      e.target.nodeName.toLowerCase()=='button')) {
        if (this.field.type().isEmbedded()){
          this.openCardForEditing(cardContext)
          return
        }
        if(this._onClickCard) {
          this._onClickCard(cardContext.data, e);
        } else if (this.openMode != 'dont.open.on.click' && this.openMode != 'dont.show.view.link') {
          if (!this.field.type().isTransient()){
            let objectId = options.objectId
            showInstance({openMode: this.openMode,
              objectId: objectId,
              edit: this.context.updating,
              typeId: this.field.type().id,
              viewId: this.linkFormView,
              callback: (data) => {
                cardContext.wizardView.getDataAfterSave()
              },
              openInNewTab: e.button == 1,
              previousContext: this.context,
              modalOpt: this.modalOpt
            })
          }
        }
    }
	}

  disable () {
    this.disabled = true
    this._get('add').addClass('disabled')
    this._get('remove').addClass('disabled')
  }

  isDisabledInFormBuilder () {
    return this.disabledInEditor
  }

  enable () {
    this.disabled = false
    this._get('add').removeClass('disabled')
    this._get('remove').removeClass('disabled')
  }

  initFilters () {
    var that = this
    let table = {
      oldTable: {
        getTableFilterFields: function(){
          return that.getFilteringFields()
        }
      },
      fieldName: this.modelAttr,
      isField: true,
      state: that.state,
      filtersChanged: function(f) {
        that.reload()
      }
    }

    initFiltersEntityTable({
      el: this._get('filters'),
      table: table,
      field: this.modelAttr,
      fields: app.fields,
      types: app.types,
      model: this.model,
      typeId: this.model.entityTypeId
    });

    return table.filters
  }

  getFields () {
    return (this.info.fieldsForFilter || []).map((f) => {
      return app.fields.get(f)
    })
  }
  getFieldsInView () {
    app.utils.getRequest('/en/entityView/' + this.viewId).then((i) => {
      i.formItems.filter((f) => {
        return f.entityField
      }).map((f) => {
        return f.entityField.id
      })
    })
  }
  getFilteringFields () {
    return this.getFields().filter((e) => (!e.isCollection() && !e.isDynamic())).map((e) => {
      return {
        field: e.fieldName(),
        fieldId: e.id,
        viewId: ''
      }
    })
  }

  getSortingFields () {
    return this.getFields().filter((e) => !e.isCollection()).map((e) => {
      return {
        field: e.fieldName(),
        fieldId: e.id,
        viewId: ''
      }
    })
  }
  setSortingToModel (json) {
    this.model.getViewContext().getOptionsForField(this.field).setOrder(json)
  }
  initSorting () {
    var that = this
    return new WidgetSorting({
      el: this._get('sorting'),
      table: {
        getTableSortingFields: function() {
          return that.getSortingFields()
        },
        orderChanged: function(newOrder) {
          if (that.sort()) {
            that.empty()
            that._render()
          }
        },
        state: this.state
      },
      fieldId: this.field,
      field: this.modelAttr,
      model: this.model,
      typeId: this.model.entityTypeId
    })
  }
  filter(filters, strict) {
		if (strict || this.filters && this.filters.getFilters().length === 0 && filters.length !== 0) {
			this.tableFilter.loadFilters(filters);
		}
	}
  _filter (newFilters) {
    this.filteredWidgetModels = utils.filterCollection(this.widgetModels, newFilters)
    this.sort()
    this.tableFilter.setFilteredLabelValue(this.filteredWidgetModels.length)
  }
  sort () {
    let order = this.model.getViewContext().getOptionsForField(this.field.id).getOrder()
    let compar
    if (this.field.type().isEmbedded()) {
      compar = (a, b) => (a.relativeOrder < b.relativeOrder ? -1 : a.relativeOrder > b.relativeOrder )
    } else {
      compar = (a, b) => (a.id < b.id ? -1 : a.id > b.id )
    }
    this.filteredWidgetModels.sort(compar)
    if (order){
      let fieldName = app.fields.get(order.field.id).fieldName()
      let comparator = getComparator(fieldName, this.field.id)
      if (comparator){
        this.sortByComparator((a, b) => comparator(a.get(fieldName), b.get(fieldName)), order)
      } else {
        let ids = this.filteredWidgetModels.map((e) =>
          e.get(fieldName)).filter((e) => e != null).map((e) => e.get('id'))
        let stringViewRequests = ids.map((e) =>
          app.entityManager.fetchStringView("", e))
        Promise.all(stringViewRequests).then((values) => {
          let idToStringValue = {}
          for (let i = 0; i < ids.length; i++){
            idToStringValue[ids[i]] = values[i]
          }
          this.sortByComparator((a, b) => {
            if (!a.get(fieldName)) return 1
            if (!b.get(fieldName)) return -1
            return idToStringValue[a.get(fieldName).get('id')].localeCompare(idToStringValue[b.get(fieldName).get('id')])
          }, order)
          this.empty()
          this._render()
        })
        return false
      }
    }
    return true
  }

  sortByComparator (comparator, order) {
    this.filteredWidgetModels.sort(comparator)
    if (!order.ascendingOrder){
      this.filteredWidgetModels.reverse()
    }
  }

  initAddItemSelect () {
    this._get('addSelect').attr('data-source', this.el.getAttribute('data-source'));

    const selectModel = new Backbone.Model();
    this.select = new FieldSelect({
      model: selectModel,
      modelAttr: 'item',
      typeId: this.field.type().id,
      el: this._get('addSelect'),
      context: {
        type: {
          id: this.model.entityTypeId
        },
        model: this.model
      }
    })

    this._get('add').first().on('click',a=>{
        this.addRow();
    });

    selectModel.on('manualChange:item', _.bind(this.onItemSelected, this));
  }

  addRow () {
    this.setIgnoreValuesIntoSelect()
    this.select.open(this)
  }

  addItem(id) {
    let url =  app.urls.update(this.field.type().id, id, {
      formViewId: this.viewId,
      notransaction: true
    })
    utils.request(null, url, 'GET').then(info => {
      let dataUrl = app.urls.data(info.typeId, info.objectId, {
          viewId: !app.builderMode && info.viewId,
          fillFunctionId: info.fillFunctionId,
          fillInstanceId: info.fillInstanceId
        });
        return utils.getRequest(dataUrl);
      }
    ).then(resp => {
      if (!app.builderMode){
         scaleDecimals(this.field.type(), resp.item)
      }
      let model = new Entity(resp.item, {
        entityTypeId: this.field.type().id,
        entityViewId: this.viewId
      })
      if (this.field.isVirtual()){
        this.renderOne({
          objectId: model.getIdOrCid(),
          model: model,
          prepend: true
        })
      } else {
        resp.item.clientState = States.NEW
        this.model.get(this.modelAttr).add(model)
      }
    })
  }

  onItemSelected () {
    var id = this.select.getValue() ? this.select.getValue().id : null;
    if (id == null) {
      return
    }
    this.select.setValue(null);

    var that = this
    var widgetModel = this.model.get(this.modelAttr).get(id)
    if (!widgetModel && id) {
      this.addItem(id)
    } else if (widgetModel && (widgetModel.get('clientState') == States.DELETED)){
      widgetModel.set('clientState', States.NEW)
      this.reload()
    }
  }

  setIgnoreValuesIntoSelect () {
    this.select.setIgnoreValues(_.filter(this.model.get(this.modelAttr).models, (item) => {
      return item.get('clientState') !== States.DELETED;
    }))
  }

  selectionChanged (model, value) {
    if (model) {
      let selectedItemId = model.get('id')
      if (value && !this.selectedItems.includes(selectedItemId)){
        this.selectedItems.push(selectedItemId)
      } else if (!value && this.selectedItems.includes(selectedItemId)){
        let index = this.selectedItems.indexOf(selectedItemId)
        if (index !== -1){
          this.selectedItems.splice(index, 1)
        }
      }
    }

    let numberOfSelectedItems = this.selectedItems.length
    this._get('selectedEntities').text(numberOfSelectedItems)
    if (numberOfSelectedItems){
      this._get('selected-button').removeAttr('disabled')
    } else {
      this._get('selected-button').attr('disabled', 'disabled')
    }
  }

  removeSelected () {
    var that = this
    this.selectedItems.forEach((id) => {
      that.model.get(that.modelAttr).get(id).set('clientState', States.DELETED)
    })
    this.reload()
    this.selectedItems = []
    this.selectionChanged()
  }

  reload () {
	if (this.field.isVirtual()) {
		this.t.then(() => {
    		this.t = this.infoPromise.then(() => {
				this.empty()
       		 	return this._render()
    		})
		})
	} else {
		this.empty()
    	this.t = this.infoPromise.then(() => {
    		this.widgetModels = this.model.get(this.modelAttr).models.filter(item => item.get('clientState') !== States.DELETED)
       		this._filter(this.tableFilter.toJSON())
       		this._render()
       	})
    }	
  }
}
export default Widget;
