import { Checkbox } from '@codejig/ui-components'

class CheckboxInput {

  constructor (options) {
    var that = this
    this.model = options.model
    this.modelAttr = options.modelAttr
    this.el = options.el
    this.disabled = this.el.getAttribute('data-disabled') == 'true'
    this.context = options.context;
    this.props = {
      disabled: this.el.hasAttribute('disabled') || false,
      value: this.model.get(this.modelAttr),
      skin: this.el.getAttribute('data-style')

    }
    this.checkbox = new Vue({
      el: that.el,
      data: {
        props: that.props
      },
      render: h => h(Checkbox, {
        props: this.props,
        ref: 'checkbox',
        on: {
          input: function (data) {
            if (that.model && that.model.get(that.modelAttr) !== data) {
              that.model.set(that.modelAttr, data)
              that.model.trigger('manualChange:' +  that.modelAttr, that.model)
            }
          }
        }
      })
    }).$refs.checkbox

    this.el = this.checkbox.$el
    this.model.on('change:' + this.modelAttr, function (model, value) {
      that.props.value = value
    })
  }

  render () {}

  focus () {
    this.checkbox.focus()
  }

  disable () {
    this.props.disabled = true
  }

  isDisabledInFormBuilder () {
    return this.disabled
  }

  enable () {
    this.props.disabled = false
  }

  isEnabled () {
    return !this.props.disabled;
  }

  reset () {
    this.checkbox.$destroy()
  }

  undelegateEvents () {}

  off () {}

}
export default CheckboxInput;
