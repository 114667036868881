import BinaryKind from '../../common/enums/binaryKind';
import BaseControl from './baseControl';
import Binary from '../../common/models/binary';
import utils, { getStyle } from './utils'
import BinaryUploaderConnector from '../../common/components/BinaryUploaderConnector'

let waitForOembedLoad

export default class BinaryInput extends BaseControl {
	initialize (options) {
		if (!$.fn.oembed && !waitForOembedLoad){
			waitForOembedLoad = Promise.all([
				import(/*webpackChunkName:'oembed2'*/'../../extensions/oembed/jquery.oembed.js'),
				getStyle(app.urls.oembedCss)
			])
		}
		this.context = options.context;
		this.model = options.model;
		this.modelAttr = options.modelAttr;
		const that = this;
		let binaryModel = this.model.get(this.modelAttr);
		if (binaryModel) {
			this.value = binaryModel.toJSON();
			this.listenTo(binaryModel, 'change', this.render)
		}
		this.listenTo(this.model, 'change:' + this.modelAttr, () => {
			this.render()
			let binaryModel = this.model.get(this.modelAttr)
			if (binaryModel) {
				this.listenTo(binaryModel, 'change', this.render)
			}
		})
		this.uploadFileUrl = this.$el.attr('data-upload-file-url')
		this.fieldId = this.$el.attr('data-field-id')
		this.hasPreview = this.$el.attr('data-file-preview').indexOf('Preview') >= 0
		this.hasPreviewLink = this.$el.attr('data-file-preview').indexOf('Link') >= 0
		this.disabled = this.$el.attr('data-disabled') == 'true';
		this.style = $(options.el).attr('style')

		this.dataFileType = this.$el.attr('data-file-type') || ""
		this.dataCustomFileExtensions = this.$el.attr('data-custom-file-extensions') || ""
		this.dropzoneEl = null;
		this.linkInput = null;

		// Prepare an HTML element to use for linking Binary Uploader component.
		// First we clean the current element to remove stub items used by form builder.
		// And then we add <div> element for the Binary Uploader
		const rootEl = this.$el.get(0);
		rootEl.classList.remove('form-control');
		rootEl.classList.remove('dropzone');
		rootEl.classList.remove('file-uploader');
		rootEl.innerHTML = '';
		rootEl.appendChild(document.createElement("div"));
		
		var funcFromUO = app.userObservers.getFileUploadProvider(this.fieldId)
		var func = null
		if (funcFromUO == null){
			func = (value) => {
				return app.urls.download(value);
			}
		} else {
			func = (value) => {
				return funcFromUO(this.model, value)
			}
		}
		// Instantiate Binary Uploader component
		this.dropzone = BinaryUploaderConnector(rootEl.childNodes[0], {

			dzOptions: {
				maxFilesize: that.$el.attr('data-file-max-size') || 5,
				dictFileTooBig: "File is too big ({{filesize}}MB). Max filesize: {{maxFilesize}}MB.",
				maxThumbnailFilesize: 1,
				createImageThumbnails: true,
				acceptedFiles: that._getAcceptedFiles(),
			},

			events : {
				onSuccess: function (file, response) {
					if (!file.mock) {
						response.fileName = file.name;
						that.setValue(response);
						that.changed();
					}
				},
				onReset: function (file, response) {
					that.setValue(null);
					that.changed();
				},
				onComplete: function (file) {
					if (that.$el.attr('data-link-click') === 'Preview') {
						that.$('.dz-filename a').removeAttr('href')
							.click(() => that._showPreviewModal());
					}
					that.$el.find('a').attr('tabindex','-1');
				}
			},
			uploadFileUrl: this.uploadFileUrl,
			buildFileDescriptionFn: function (file, binary) {
				return `<a href="${file.url}" tabindex="-1">${file.name}</a>`
			},
			buildFileDownloadUrlFn: func
		}).connect();

		// Store handler for the Binary Uploader
		this.dropzoneEl = $(rootEl.childNodes[0]);
		super.initialize(options);
		if (this.disabled || this.$el[0].hasAttribute('disabled')) {
			this.disable();
		}
		this.dropzoneEl.attr('style', this.style)

		this._setupLinkModeControls()
		this._updatePreview()
	}

	_getAcceptedFiles () {
		let result = ""
		let wasAny = false
		this.dataFileType.split(',').forEach((type)=>{
			result && (result += ', ')
			switch (type) {
				case 'Image':
				result += 'image/*'
				break
				case 'Video':
				result += 'video/*'
				break
				case 'Audio':
				result += 'audio/*'
				break
				case 'Document':
				result += '.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .odt, .sxw'
				break
				case 'PDF':
				result += '.pdf'
				break
				case 'Custom':
				result += this.dataCustomFileExtensions
				break
				default:
				wasAny = true
			}
		})
		if (wasAny){
			return undefined
		}else{
			return result
		}
	}

	_setupLinkModeControls() {

		// Link input control
		if (this.$el.attr('data-upload').indexOf('Link') >= 0) {
			this.dropzoneEl.append(`<div class="link-input-form"><input class="link-input" placeholder="File URL"/></div>`);
		}
		if (this.$el.attr('data-upload').indexOf('Link') >= 0) {
			this.linkInput = this.$('.link-input');
			this.linkInput.on('paste', () => setTimeout(() => this._addFromLink(), 0));
			this.linkInput.blur(() => this._addFromLink());
		}

		// Link mode flag
		if (this.$el.attr('data-upload') == 'Link') {
			this.dropzoneEl.addClass('link-mode');
			this.dropzone.disable(true);
		}

		// Link mode switchers

		if (this.$el.attr('data-upload') == 'File & Link') {
			this.dropzoneEl.append(`<a herf="javascript:void(0)" class="mode-link link-mode-link" tabindex="-1"></a>`);
			this.dropzoneEl.append(`<a herf="javascript:void(0)" class="mode-link file-mode-link" tabindex="-1"></a>`);
		}

		this.$('.link-mode-link')
			.click(() => {
				this.dropzoneEl.addClass('link-mode');
				this.dropzone.disable(true);
			})
			.html(app.getResource('external.link'));
		this.$('.file-mode-link')
			.click(() => {
				this.dropzoneEl.removeClass('link-mode');
				if (this.$el.attr('disabled') != 'disabled') {
					this.dropzone.enable();
				}
			})
			.html(app.getResource('upload.file'));
	}

	_addFromLink() {
		//is this valid url?
		if (this._isUrl(this.linkInput.val())) {
			this.linkInput.removeClass('has-errors');
			this.setValue(this.linkInput.val() && {
				kind: BinaryKind.EXTERNAL,
				url: this.linkInput.val()
			});
		} else {
			this.linkInput.addClass('has-errors');
		}
	}

	_isUrl(link) {
		return /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i
			.test(link);
	}

	_updatePreview () {

		// Hide link related elements if link should be not previewed
		if (!this.hasPreviewLink) {
			this.dropzoneEl.addClass('no-link');
		}

		const that = this;

		// Make the control open the selection dialog
		// on enter or space pressing if nothing selected yet
		this.$el.off('keypress').on('keypress', function (e) {
			if (!that.value && that._isFileSelectionEnabled()) {
				if (e.keyCode == 13 || e.keyCode == 32) {
					e.preventDefault();
					that.dropzone.openSelectionWindow();
				}
			}
		});

		// Build the preview section

		if (!this.hasPreview) {
			return;
		}

		if (this.$('.binary-preview').length == 0) {
			this.dropzoneEl.prepend(`<div class="binary-preview"><span class="fa fa-ban"/></div>`);
		}
		this.$('.binary-preview').off('click')
		if (this.value) {
			const url = app.urls.download(this.value);
			this.$('.binary-preview').addClass('isFile')
			this._buildPreview(this.$('.binary-preview'), url)
		} else {
			this.$('.binary-preview').removeClass('isFile');
			this.$('.binary-preview').html('<span class="fa fa-ban"/>');
		}

		// Make the stub preview image open the selection dialog on click if nothing selected yet
		if (!that.value) {
			this.$('.binary-preview').click((e) => {
				if (that._isFileSelectionEnabled()) {
					e.stopPropagation();
					that.dropzone.openSelectionWindow();
				}
			});
		}
	}

	_buildPreview(el, url){
		if (this.value.kind == BinaryKind.EXTERNAL){
			if (this._embeddedVideoLink(url)) {
				url = url.replace('youtu.be', 'youtube.com/embed')
				el.html(`<a class="oembed" href="${url}" tabindex="-1"></a>`);
				this._useOembed(el.find('a'))
			} else if (this.dataFileType == 'Video') {
				this._buildVideoPreview(el, url)
			} else {
				this._buildImagePreview(el, url, false)
			}
		} else {
			if (this._isValueImage()) {
				this._buildImagePreview(el, url, true)
			}
			if (this._isValuePdf()) {
				el.html(`<iframe style="width:100%;height:100%" src=${app.urls.PDFviewer(url)} ></iframe>`);
			}
			if (this._isValueVideo()) {
				this._buildVideoPreview(el, url)
			}
			if (this._isValueAudio()) {
				el.html(`<audio controls><source src="${url}"></audio>`);
			}
		}
	}

	_buildVideoPreview(el, url){
		el.html(`<video controls><source src="${url}"></video>`)
	}

	_buildImagePreview(el, url, isInternal){
		el.html('<img />');
		el.find('img')
		.error(() => {
			let html = `<span style="font-size: 11pt">${app.getResource('preview.is.not.available')}</span>
			<span class="fa fa-ban"/>`;
			if (this.$el.is('.updating *')) {
				html = `<span style="font-size: 11pt">${app.getResource('try.to.save.item')}</span>` + html;
			}
			el.html(html);
		}).attr('src', url + (isInternal ? '&serveAsImage=true' : ''));
		if (this.$el.is('[data-zoom-on-click]')) {
			this.$el.click((e) => {
				e.stopPropagation();
				this._showPreviewModal();
			});
		}
	}

	_getFileContentType () {
		return this.value.internalFileInfo && this.value.internalFileInfo.fileContentType || ""
	}

	_isValueImage () {
		return this._getFileContentType().split('/').find((d)=>{return d == 'image'})
	}

	_isValuePdf () {
		return this._getFileContentType().split('/').find((d)=>{return d == 'pdf'})
	}

	_isValueVideo () {
		return this._getFileContentType().split('/').find((d)=>{return d == 'video'})
	}

	_isValueAudio () {
		return this._getFileContentType().split('/').find((d)=>{return d == 'audio'})
	}

	_isFileSelectionEnabled () {
		return !this.disabled &&
			!this.$el.attr('disabled') &&
			!(this.dropzoneEl && this.dropzoneEl.hasClass('link-mode'));
	}

	_showPreviewModal () {
		const url = app.urls.download(this.value);
		$('#preview-modal').removeClass('with-iframe');
		 if (this._isValuePdf() || (this.value.kind == BinaryKind.EXTERNAL) &&(this._embeddedVideoLink(url))){
			  $('#preview-modal').addClass('with-iframe');
		 }
		this._buildPreview($('#preview-modal .modal-content'),url)

		$('#preview-modal').show();
		$('body').addClass('previewing');

		$('#preview-modal .modal-content').append('<i class="close material-icons notranslate">close</i>')
		$('#preview-modal .close').off('click').on('click', () => {
			this._hidePreviewModal();
		});
		$(document).off('keyup').on('keyup', (e) => {
			if (e.keyCode == 27) {
				this._hidePreviewModal();
			}
		});
	}

	_hidePreviewModal() {
		$('body').removeClass('previewing');
		$('#preview-modal').hide();
	}

	_embeddedVideoLink(link) {
		const services = {
			youtube: /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})?$/,
			vimeo: /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/
		}
		return _.some(_.map(services, exp => exp.test(link)));
	}

	_useOembed ($el) {
		waitForOembedLoad.then(() => {
			$el.oembed(null, {
				embedMethod: 'fill'
			})
		})
	}

	getValue () {
		return this.value;
	}

	setValue (value) {
		if (!this._equalValues(this.value,value)){
			this.value = value
			this._updatePreview()
		}
		this.dropzone.setValue(value)
	}

	_equalValues (a, b) {
		if (a && b) {
			if (a.kind !== b.kind) {
				return false
			}
			if (a.kind == BinaryKind.INTERNAL) {
				if (a.internalFileInfo.id !== b.internalFileInfo.id) {
					return false
				}
			} else {
				if (a.url !== b.url) {
					return false
				}
			}
			return true
		} else {
			if (a === b) {
				return true
			} else {
				return false
			}
		}
	}

	setDataToModel (data) {
		const oldValue = this.model.get(this.modelAttr);
		if (!data) {
			if (oldValue) {
				this.model.set(this.modelAttr, data)
				this.model.trigger('manualChange:' +  this.modelAttr, this.model);
			}
		} else if (!_.isEqual(oldValue && oldValue.toJSON(), data)) {
			this.model.set(this.modelAttr, new Binary(data))
			this.model.trigger('manualChange:' +  this.modelAttr, this.model);
		}
	}

	getDataFromModel () {
		const value = this.model.get(this.modelAttr);
		return value && value.toJSON();
	}

	isDisabledInFormBuilder () {
		return this.disabled
	}

	enable () {
		this.$el.removeAttr('disabled');
		this.dropzone.enable && this.dropzone.enable(
			this.dropzoneEl && this.dropzoneEl.hasClass('link-mode'));

		// Fix tab order
		this.$el.parent().find('[tabindex-orig]').each(( index, elem ) => {
			$(elem).attr('tabindex', $(elem).attr('tabindex-orig'));
		});
	}

	disable () {
		this.$el.attr('disabled', 'disabled');
		this.dropzone.disable && this.dropzone.disable();
		// Fix tab order
		this.$el.parent().find('[tabindex]').each(( index, elem ) => {
			if (!$(elem).attr('tabindex-orig')) {
				$(elem).attr('tabindex-orig', $(elem).attr('tabindex'));
			}
			$(elem).attr('tabindex', '-1');
	  });
	}

	destroy () {
		this.undelegateEvents();
	}
}
