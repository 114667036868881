import utils from '../common/components/utils';
import websocketClient from '../websocket/client';

// Chat Assistant
var CodejigChat = Backbone.View.extend({
    
    $tutorials: null,

    md: window.markdownit && window.markdownit(),

    isOpened: false,
    messagesLoaded: false,

    savedImageData: null,

    lastRole: null,
    lastMsgId: null,


    initialize: function (tutorials) {
        this.$tutorials = tutorials;        
        this.$tutorials.find('.nav-tabs').append(`<li>
            <a href="#chat_gptTab" data-url="chat_gpt" data-toggle="tab" aria-expanded="true">
            <span>AI</span>
            </a>
        </li>`);
        this.$tutorials.find('.tab-content').append(`<div class="tab-pane" id="chat_gptTab"></div>`);
    },

    removeChat: function () {
        document.getElementsByTagName("body").item(0).classList.remove('tutorial-opened-chat');
        this.$tutorials.find(`#chat_gptTab`).html("");
        this.isOpened = false;
        this.messagesLoaded = false;

        this.destroyListeners();
    },
      
    addChat: async function () {
        this.$tutorials.find(`#chat_gptTab`).html("");
        document.getElementsByTagName("body").item(0).classList.add('tutorial-opened-chat');

        this.$tutorials.find(`#chat_gptTab`).append(
            `<div class="aichat">
              <div class="messagesContent scrollable ps-container ps-theme-default ps-active-y"></div>
              <div class="dropArea" style="display: none;">Drag & Drop images here</div>
              <div class="chatInput">
                <div class="tryAgain" style="display: none;">Try again</div>
                <span class="waiter" style="display: none;">AI is writing ...</span>
                <span class="loadImage"><span class="glyphicon glyphicon-paperclip"></span></span>
                <span class="hideImage" style="display: none;"><span class="glyphicon glyphicon-remove"></span></span>
                <img class="displayImage" src="">
                <input type="file" class="imageInput" accept="image/*" style="display:none;">
                <span class="downbtn" style="display: none;"><span class="glyphicon glyphicon-arrow-down"></span></span>
                <textarea placeholder="Type some text here..."></textarea>
                <div class="styleDiv"><div></div></div>
                <a type="button">
                  <span class="glyphicon glyphicon-send" ></span>
                </a>
              </div>
            </div>`
        );
        this.$chat_gptTab = this.$tutorials.find(`#chat_gptTab`);
        this.$chatInput = this.$tutorials.find(`#chat_gptTab .chatInput textarea`);
        this.$chatSendBtn = this.$tutorials.find(`#chat_gptTab .chatInput a`);
        this.$messagesContent = this.$tutorials.find(`#chat_gptTab .messagesContent`);
        this.$waiter = this.$tutorials.find(`#chat_gptTab .waiter`);
        this.$loadImage = this.$tutorials.find(`#chat_gptTab .loadImage`);
        this.$hideImage = this.$tutorials.find(`#chat_gptTab .hideImage`);
        this.$displayImage = this.$tutorials.find(`#chat_gptTab .displayImage`);
        this.$image = this.$tutorials.find(`#chat_gptTab .imageInput`);
        this.$dropArea = this.$tutorials.find(`#chat_gptTab .dropArea`);
        this.$tryAgain = this.$tutorials.find(`#chat_gptTab .tryAgain`);
        this.$downbtn = this.$tutorials.find(`#chat_gptTab .downbtn`);

        this.$chatInput.on("input", function () {this.style.height = 0;this.style.height = (this.scrollHeight) + "px";});
    
        this.$chatSendBtn.on("click", (e) => {this.sendMessage()});
        this.$chatInput.on("keypress", (e) => { if (e.which == 13 && !e.shiftKey) {e.preventDefault();this.sendMessage()}});

        this.$messagesContent.on( 'scroll', (e) => {
            if (this.$messagesContent[0].scrollTop + this.$messagesContent[0].clientHeight + 10 < this.$messagesContent[0].scrollHeight) {
                this.$downbtn.show();
            } else {
                this.$downbtn.hide();
            }
         });
        this.$downbtn.on("click", (e) => {this.scrollBottom(); this.$downbtn.hide();});

        this.$loadImage.on("click", (e) => {
            this.$image.click();
        });
        this.$hideImage.on("click", (e) => {
            this.savedImageData = null;        
            this.$displayImage.attr('src', "");        
            this.$loadImage.show();
            this.$hideImage.hide();
        });

        this.$chatInput.on("paste", (e) => {
            const items = e.originalEvent.clipboardData.items;
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (item.type.startsWith('image/')) {
                    const blob = item.getAsFile();
                    if (blob) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            this.savedImageData = e.target.result;
                            this.$displayImage.attr('src', e.target.result);
                            this.$hideImage.show();
                            this.$loadImage.hide();
                        };
                        reader.readAsDataURL(blob);
                    }
                }
        }});
        this.$image.change((e) => {
            const file = e.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.savedImageData = e.target.result;
                    this.$displayImage.attr('src', e.target.result);
                    this.$hideImage.show();
                    this.$loadImage.hide();
                };
                reader.readAsDataURL(file);
            }
        });

        this.$tutorials.find(`#chat_gptTab`).on('dragenter dragover dragleave drop', (e) => {e.preventDefault(); e.stopPropagation();});
        this.$tutorials.find(`#chat_gptTab`).on('dragenter dragover', () => {this.$dropArea.show();});
        this.$tutorials.find(`#chat_gptTab`).on('dragleave drop', () => {this.$dropArea.hide();});
        this.$tutorials.find(`#chat_gptTab`).on('drop', (e) => {
            let file = e.originalEvent.dataTransfer.files[0];
            if (file.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.savedImageData = e.target.result;
                    this.$displayImage.attr('src', e.target.result);
                    this.$hideImage.show();
                    this.$loadImage.hide();
                };
                reader.readAsDataURL(file);
            }
        });

        this.$tryAgain.on("click", (e) => {
            this.$tryAgain.hide();
            this.$waiter.show();
            this.tryAgain();
        });

        this.isOpened = true;

        this.$waiter.show();

        await this.createChatAndLoadContext();
    },

    sendMessage: function () {
        if (this.$chatInput.val()) {
          let message = this.$chatInput.val();      
          this.$chatInput.val("");
          this.$chatInput.height("40px");
          this.$waiter.show();
    
          this._sendMessage(message);

          this.savedImageData = null;        
          this.$displayImage.attr('src', "");        
          this.$loadImage.show();
          this.$hideImage.hide();
        }
    },

    fillMsg: async function (msg) {
        if(this.messagesLoaded && msg.type == "contextLoading") return;
        if(msg.type == "endcontextLoadingFirstRun") {
            this.messagesLoaded = true;
            return;
        }

        if(msg.role == "assistant") {
            this.$waiter.hide();
        }

        if (this.lastRole == msg.role && this.lastMsgId == msg.id && this.$messagesContent.find(".chatMessage:last").hasClass(msg.role)) {
            for(let con of msg.content.values()) {
                let span = this.$messagesContent.find(".chatMessage." + msg.role + ":last span") 
                    if (con.type == "text"){
                        this.lastText[msg.index] = (this.lastText[msg.index] || "") + con.text.value; 
                    } else if(con.type == "image_file") {
                        if(con.image_file) {
                            this.lastText[msg.index] = (this.lastText[msg.index] || "") + " ![user image](" + con.image_file + ")";
                        }
                    }         
                    
                    span.html(this.chatgptToHtml(this.formLastText()));
            }
        } else {
            this.lastRole = msg.role;
            this.lastMsgId = msg.id;

            this.lastText = {};
            
            for(let con of msg.content.values()) {
                if (con.type == "text"){
                    this.lastText[msg.index] = (this.lastText[msg.index] || "") + con.text.value; 
                } else if(con.type == "image_file") {
                    if(con.image_file) {
                        this.lastText[msg.index] = (this.lastText[msg.index] || "") + " ![user image](" + con.image_file + ")";
                    }
                } 
            }

            let spanBefore = null;
            let spanAfter = null;
            for (let i = msg.index; i>=0; i--) {
                spanBefore = this.$messagesContent.find(`.chatMessage[data-msgindex="${i}"]:last`);
                if(spanBefore.length > 0) {
                    break;
                }
            }
            if(!spanBefore || spanBefore.length == 0) {
                let lastIndex = this.$messagesContent.find(`.chatMessage:last`).data("msgindex");

                for (let i = msg.index; i<=lastIndex; i++) {
                    spanAfter = this.$messagesContent.find(`.chatMessage[data-msgindex="${i}"]:first`);

                    if(spanAfter.length > 0) {
                        break;
                    }
                }
            }

            let msgHtml = `<div class="chatMessage ${msg.role}" data-msgid="${msg.id}"  data-msgindex="${msg.index}"><span>${this.chatgptToHtml(this.formLastText())}</span><div class="styleDiv"><div></div></div></div>`;
            if(spanBefore && spanBefore.length > 0) {
                spanBefore.after(msgHtml)
            } else if(spanAfter && spanAfter.length > 0) {
                spanAfter.before(msgHtml)
            } else {
                this.$messagesContent.append(msgHtml);
            }

            this.updateScroll();
        }        
    },

    updateScroll: function () {
        this.$messagesContent.perfectScrollbar('destroy');
        this.$messagesContent[0].scrollTop = this.$messagesContent[0].scrollTop + $(`#chat_gptTab .messagesContent .chatMessage:last`)[0].getBoundingClientRect().top - 100;
        this.$messagesContent.perfectScrollbar({minScrollbarLength: 40});
    },

    scrollBottom: function () {
        this.$messagesContent.perfectScrollbar('destroy');        
        this.$messagesContent.animate({scrollTop: this.$messagesContent[0].scrollHeight - this.$messagesContent[0].clientHeight}, 1000);
        this.$messagesContent.perfectScrollbar({minScrollbarLength: 40});
    },

    formLastText: function() {
        let text = '';

        Object.keys(this.lastText)
            .sort(function(a, b) {return a - b;})
            .forEach(index => {text = text + this.lastText[index];});

        return text;
    },

    chatgptToHtml: function(text) {
        text = this.md && this.md.render(text);
        
        // remove Source
        text = text ? text.replace(/【(.*)】/gim, '') : '';

        return text;
    },




    initCodejigChatListener: async function () {
        let that = this;

        if(websocketClient.connectPromise) {
            await new Promise((resolve, reject) => {
                websocketClient.connectPromise.then(() => {
                    websocketClient.registerCodejigChatListener((msg)=>{that.codejigChatListener(msg)});
                    resolve();
                });
            })
        }
    },

    codejigChatListener: function (msg) {
        if(this.isOpened){
            if(msg.isFailed) {
                this.$waiter.hide();
                app.notificationManager.addError("Chat failed. Try again.");
                this.$tryAgain.show();
            } else {
                this.fillMsg(msg);
            }
        }
    },

    destroyListeners: function () {
        websocketClient.unregisterCodejigChatListener();
    },

    createChatAndLoadContext: async function () {
        await this.initCodejigChatListener();
        await utils.getRequest(app.urls.home + "codejig-gpt/createChatAndLoadContext");
    },

    tryAgain: async function () {
        await utils.getRequest(app.urls.home + "codejig-gpt/tryAgain");
    },

    _sendMessage: function (message) {
        utils.ajaxRequest({
          "message": message,
          "image": this.savedImageData || null
        }, app.urls.home + "codejig-gpt/sendMessage",'post', ()=>{}, ()=>{}, true);
    }

})
export default CodejigChat;