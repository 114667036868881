import utils from './utils';
import MultilingualString from '../models/multilingualString';
import PrimitiveEntityType from '../enums/primitiveEntityType';
import ValidationMessage from '../models/validationMessage';
import Constants from '../models/constants';
import * as BlockUtils from './blockUtils';

let isNull = v => v === undefined || v === null || v === '';

export default class ValidationContext extends Backbone.View {
	initialize (o) {
		this.context = o.context;
		this.model = o.context.model;
		this.messages = new Backbone.Collection();
		this.collections = []
		this.listenTo(this.messages, 'add', this._messageAdded);
		this.listenTo(this.messages, 'remove', this._messageRemoved);
		this.listenTo(this.messages, 'change:message', this._messageChanged);;
	}

	addNewMessage (message, path, attrValue, context) {
		let validationMessage = new ValidationMessage({
			path: path,
			message: '',
			context: this
		});
		let message2;
		if (_.isObject(message)) {
			message2 = new MultilingualString(message)
		} else {
			let rs = app.getResource(message)
			if (attrValue) {
				message2 = BlockUtils.format(rs,	new MultilingualString({ value: attrValue.toString() }))
			} else {
				message2 = BlockUtils.format(rs)
			}
		}
		validationMessage.set('message', message2)
		this.messages.add(validationMessage);
	}

	_messageAdded (m) {
		if (!m.get('path') || !m.get('path').innerField) {
			app.notificationManager.addError(m.getValidationMessage().getCurrentValue());
		} else if (m.get('path').elementIdentifier !== undefined &&
			m.get('path').elementIdentifier !== null) {


		} else {
			let input = m.getFieldControl();
			if (!input.hasClass('form-group')) {
				input.closest('.form-group').addClass('has-error has-validation-error');
			} else {
				input.addClass('has-error has-validation-error');
			}
			let fgroup = input.hasClass('form-group') ?
									input :
									input.closest('.form-group');
			const $span = $(`<span class="help-block validation-error" id="${m.cid}"/>`)
									.appendTo(fgroup);
			if (m.getValidationMessage()) {
				$span.append(m.getValidationMessage().toObject());
			};
		}
	}

	_messageRemoved (m) {
		let formgroup = $('#' + m.cid).closest('.form-group');
		$('#' + m.cid).remove();
		if (formgroup.find('.validation-error').length === 0) {
			formgroup.removeClass('has-error has-validation-eror');
		}
	}

	_messageChanged (m) {
		let $el = $('#' + m.cid);
		if (m.getValidationMessage()) {
			let html = m.getValidationMessage().toHTML();
			if ($el.is('[data-toggle="popover"]')) {
				$el.attr('data-content', html);
			} else {
				$el.html(html);
			}
		}
	}

	_checkFromItemsConstraints () {
		this.$el.find('[data-field]').each((i, f) => this.check($(f)));
	}

	_checkValue (value, attrValue, cond, reducer, initial) {
		let valid;
		if (value && value.getTranslations) {
			valid = _.chain(value.getTranslations().toJSON())
				.filter((v, k) =>
						_.where(app.enabledLanguages, {languageTag: k}).length)
				.concat(value.getValue())
				.map(v => cond(v, attrValue))
				.reduce(reducer, initial)
				.value();
		} else {
			valid = cond(value, attrValue);
		}
		return valid;
	}

	_checkAttribute ($el, attrName, msgKey, cond,
			reducer = (c, v) => c && v, initial = true) {
		if ($el.is(`[${attrName}]`)) {
			let attrValue = $el.attr(attrName);
			this._checkByValue ($el, attrValue, msgKey, cond, reducer, initial);
		}
	}

	_checkByValue ($el, checkValue, msgKey, cond,
			reducer = (c, v) => c && v, initial = true) {
		if (checkValue) {
			let innerField = utils.getDataFieldAttr($el);
			let outerField;
			if (innerField.indexOf('.') > -1) {
				let fields = innerField.split('.')
				outerField = fields[0]
				innerField = fields[1]
			}
			let value;
			if (outerField) {
				value = this.model.get(outerField);
				if (value) {
					value = value.get(innerField);
				}
			} else {
				value = this.model.get(innerField);
			}

			if (!this._checkValue(value, checkValue, cond, reducer, initial)) {
				this.addNewMessage(msgKey, {
					outerField: outerField,
					innerField: innerField
				}, checkValue);
			}
		}
	}

	_checkRequired ($el) {
		this._checkAttribute(
			$el,
			'data-required',
			'validation.required',
			(value, limit) => !isNull(value),
			(v,c) => v || c,
			false);
	}

	_checkMinLength ($el) {
		this._checkAttribute(
			$el,
			'data-min-length',
			'validation.min.length',
			(value, limit) => isNull(value) || value.length >= +limit);
	}

	_checkMaxLength ($el) {
		let primitiveType = $el.attr('data-primitive-type');
		let field = this.context.type.fieldByName($el.attr('data-field') || "")
		let isTransient = field && field.isTransient()
		if(primitiveType && (primitiveType == PrimitiveEntityType.STRING || primitiveType == PrimitiveEntityType.SYSTEM_STRING) && !isTransient) {
			if ($el.attr('data-max-length') <= Constants.STRING_MAX_LENGTH) {
				this._checkAttribute(
					$el,
					'data-max-length',
					'validation.max.length',
					(value, limit) => isNull(value) || value.length <= +limit);
			} else {
				this._checkByValue(
					$el,
					Constants.STRING_MAX_LENGTH,
					'validation.max.length',
					(value, limit) => isNull(value) || value.length <= +limit);
			}
		}
	}

	_checkMinValue ($el) {
		this._checkAttribute(
			$el,
			'min',
			'validation.min.value',
			(value, limit) => isNull(value) || +value >= +limit * Math.pow(10,
					this.context.type
						.fieldByName($el.attr('data-field')).type().decimalScale()));
	}

	_checkMaxValue ($el) {
		this._checkAttribute(
			$el,
			'max',
			'validation.max.value',
			(value, limit) => isNull(value) || +value <= +limit * Math.pow(10,
					this.context.type
						.fieldByName($el.attr('data-field')).type().decimalScale()));
	}

	_checkPattern ($el) {
		this._checkAttribute(
			$el,
			'pattern',
			'invalid.value',
			(value, limit) =>  isNull(value) || new RegExp(limit).test(value)
		);
	}

	_clearMessagesForField (field) {
		this.messages.filter(m => m.get('path') === null || (m.getFieldControl() &&
				m.getFieldControl().attr('data-field') === field))
			.forEach(m => this.messages.remove(m));
	}

	_clearValidationForField (field) {
		let msgs = this.messages.filter(m => m.get('path').outerField === field)
		this.messages.remove(msgs)
	}

	check ($el) {
		if ($el.parents('[data-is-embedded="true"].modal.fade').length || $el.parents('.cjg-table').length) {
			return // HOTFIX: skip controls for table edit mode for now
		}
		this._clearMessagesForField(utils.getDataFieldAttr($el));
		this._checkRequired($el);
		this._checkMinLength($el);
		this._checkMaxLength($el);
		this._checkPattern($el);
		this._checkMinValue($el);
		this._checkMaxValue($el);
	}

  addCollection (collectionFieldName, columns, showErrors) {
    this.collections.push({
      name: collectionFieldName,
      columns: columns,
      showErrors: showErrors
    })
  }

  _checkAllCollections () {
    _.each(this.collections, (c) => {
      this._checkCollection(c.name)
    })
  }

  _checkCollection (collectionFieldName) {
    let collection = this.collections.filter((c) => {
      return c.name == collectionFieldName
    })[0]
    _.each(collection.columns, col => {
      if (col.required) {
        this.checkCollectionColumn(collectionFieldName, col.field);
      }
    });
    collection.showErrors(this.getMessagesForField(collectionFieldName))
  }

  checkCollection (collectionFieldName) {
    this._clearValidationForField(collectionFieldName)
  	this._checkCollection(collectionFieldName)
	}

	checkCollectionColumn (outerField, innerField) {
		this.model.get(outerField).forEach(v => {
			let identifier = v.get('id');
			let value = v.get(innerField);
			if (!this._checkValue(
				value,
				true,
				(value, limit) => !!value,
				(v,c) => v || c,
				false)) {
				this.addNewMessage('validation.required', {
					outerField: outerField,
					elementIdentifier: identifier,
					innerField: innerField
				});
			}
		});
	}

  validate () {
	this._clearAllMessages()
    this._checkFromItemsConstraints()
		this._checkAllCollections()
    this._showFirstValidationError()
    return this.messages.length === 0
  }

	_clearAllMessages() {
		//triggers 'remove' for each message
		this.messages.remove(this.messages.models.slice());
	}

	_showFirstValidationError() {
		if (this.messages.length) {
			let model = this.messages.models[0];
			let msg = null;
			if (model.get('path').innerField) {
				let fgroup = $(`[data-field="${model.get('path').innerField}"]`).first()
				while ((fgroup.length != 0) && !fgroup.is('body')) {
					let fgroupParent = fgroup.parent();
					if (fgroupParent.is('.tab-pane')) {
						let navTabs = fgroupParent.parent().parent().children().first();
						navTabs.find(`a[data-target="#${fgroupParent.attr('data-item-id')}"]`).tab('show');
						navTabs.parent().find('> .tab-content > div.tab-pane.active').removeClass('active');
						navTabs.parent().find(`div.tab-pane[data-item-id="${fgroupParent.attr('data-item-id')}"]`).addClass('active');
					}
					if (fgroupParent.is('.box-body')) {
						fgroupParent.css('display', 'block');
						fgroupParent.parent().children().first().removeClass('collapsed-box');
					}
					
					fgroup = fgroupParent;
				}
				let field = model.get('path').innerField;
				if (!_.isObject(field) && model.get('path').outerField) {
					field = model.get('path').outerField + '.' + model.get('path').innerField;
				}

				let fieldName = ''
				if (_.isObject(field)) {
					fieldName = app.fields.get(field.id).get('name')
				} else {
					var table = this.collections.find( (collection) => { return collection.name == model.get('path').outerField})
					if (table) {
						var column = table.columns.find( (c) => { return c.field == model.get('path').innerField})
						fieldName = this.getPlainText(column.title)
					} else {
						fieldName = this.context.type.fieldByName(field).get('name');
					}

				}

				msg = BlockUtils.format(
					app.getResource("validation.error.in.field"),
				 	fieldName,
					model.getValidationMessage());

			} else {
				msg = BlockUtils.format(
					app.getResource("validation.error"),
					model.getValidationMessage());
			}
			app.notificationManager.addError(msg.getCurrentValue());
		}
	}

	getPlainText (html) {
		const a = document.createElement('div')
		a.innerHTML = html
		return a.textContent.trim()
	}

	getMessagesForField(field) {
		return this.messages.filter(m => m.get('path').outerField === field).map(m => ({
			path: m.get('path'),
			message: m.getValidationMessage().getCurrentValue()
		}))
	}

	destroy() {
		this.undelegateEvents();
		this.remove();
	}
}
